import React, { useState } from "react";
import axios from "axios";

const ImageUpload = ({ onUploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setPreview(URL.createObjectURL(event.target.files[0]));
  };

  const onFileUpload = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post(
        "https://retreat.canaofgalilee.oo.gd/upload",
        // "http://localhost:5000/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setUploading(false);
      onUploadSuccess(response.data.file); // Pass the uploaded file URL to the parent
    } catch (error) {
      setUploading(false);
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div>
      <h2>Upload the deposite receipt</h2>
      <p>
        If you have not yet paid the 50% deposit, please follow this{" "}
        <a
          href="https://checkout.square.site/merchant/4K8CR3GSZRSRK/checkout/ZKMAUW4FWJDA5RFJZSMWM4WZ"
          target="blank"
        >
          Link
        </a>{" "}
        to pay and take a screenshot of the receipt to be attached here{" "}
      </p>
      <input type="file" onChange={onFileChange} className="mb-3" />
      {preview && <img src={preview} alt="Preview" width="100" />}
      <button type="button" onClick={onFileUpload} disabled={uploading}>
        {uploading ? "Uploading" : "Upload Receipt"}
      </button>
    </div>
  );
};

export default ImageUpload;
