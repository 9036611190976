import React, { useEffect, useState } from "react";
import { ListGroup, Modal } from "react-bootstrap";

const RoomDetailsModal = ({ show, handleClose, roomDetails }) => {
  const featuers = roomDetails.featuers;

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{roomDetails.roomName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{roomDetails.desc}</p>
        <ListGroup variant="flush">
          {featuers &&
            featuers.map((feature) => (
              <ListGroup.Item>{feature}</ListGroup.Item>
            ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default RoomDetailsModal;
