import React, { useEffect, useState } from "react";
import "./ReservationForm.css";
import { Container, Spinner } from "react-bootstrap";
import ImageUpload from "./ImageUpload";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";

const ReservationForm = () => {
  const navigate = useNavigate();
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    numberAdults: 2,
    numberChild: 0,
    room_code: "",
    depositReceipt: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://retreat.canaofgalilee.oo.gd/reserve",
        // "http://localhost:5000/reserve",
        formData
      );
      if (response) {
        setLoading(false);
        emailjs
          .send(
            "service_r6s5phx",
            "template_6rfcsoe",
            formData,
            "aDesBBxokV_GE4UyP"
          )
          .then((result) => {
            console.log("Email sent successfully:", result.status, result.text);
          })
          .catch((error) => {
            console.error("Error sending email:", error);
          });

        toast.success(
          "Reservation made successfully!, please check your email"
        );
        setTimeout(() => navigate("/"), "3000");
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html: `Error making reservation:<br>${error.response.data.message}`,
          }}
        />
      );
    }
  };

  const handleUploadSuccess = (fileUrl) => {
    setFormData({
      ...formData,
      depositReceipt: fileUrl,
    });
  };

  useEffect(() => {
    // Fetch available rooms from the backend
    const fetchRooms = async () => {
      setLoading(true);
      try {
        const fetchedRooms = await axios.get(
          "https://retreat.canaofgalilee.oo.gd/rooms"
          // "http://localhost:5000/rooms"
        );

        setRooms(fetchedRooms.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching rooms:", error);
      }
    };
    fetchRooms();
  }, []);

  if (loading) {
    return (
      <Container
        style={{ height: "100vh", fontSize: "250px" }}
        className="d-flex justify-content-center align-items-center"
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }
  return (
    <Container>
      <ToastContainer />
      <div className="jumbotron m-5">
        <h1 className="display-3 text-center">Start a new Reservation</h1>
        <p className="lead">Please fill in the following details</p>
        <hr className="my-2" />
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="phone">Phone:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="attendees">Attendees:</label>
          <div id="attendees">
            <label htmlFor="adults">Adults:</label>
            <input
              type="number"
              id="adults"
              name="numberAdults"
              value={formData.numberAdults}
              onChange={handleChange}
              min={2}
              max={5}
              required
            />
            <label htmlFor="child">Children:</label>
            <input
              type="number"
              id="child"
              name="numberChild"
              value={formData.numberChild}
              onChange={handleChange}
              min={0}
              required
            />
          </div>
        </div>
        <div>
          <label htmlFor="room_code">Room Type:</label>
          <select
            id="room_code"
            name="room_code"
            value={formData.room_code}
            onChange={handleChange}
            required
          >
            <option value="">Select room</option>
            {rooms.map((room) => (
              <option
                value={room.room_code}
                key={room.room_code}
                disabled={room.available_rooms < 1}
              >
                {/* {room.room_description} - ${room.room_price} - Available:{" "}
                {room.available_rooms} */}
                {room.room_description} - ${room.room_price}
              </option>
            ))}
          </select>
        </div>
        <div></div>
        {!formData.depositReceipt ? (
          <ImageUpload onUploadSuccess={handleUploadSuccess} />
        ) : (
          <div className="receipt">
            <img src={formData.depositReceipt} alt="receipt" />
          </div>
        )}
        <button disabled={!formData.depositReceipt} type="submit">
          Reserve Now
        </button>
      </form>
    </Container>
  );
};

export default ReservationForm;
