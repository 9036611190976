import React, { useEffect, useState } from "react";
import { Table, Container, Row, Col } from "react-bootstrap";
import axios from "axios";

const AdminDashboard = () => {
  const [reservations, setReservations] = useState([]);
  const roomPrices = {
    resort: 520,
    "1bedroom": 650,
    "2bedroom": 1100,
    visit: 50,
  };
  const [total, setTotal] = useState(0);
  const [outstanding, setOutstanding] = useState(0);

  const handelClick = async (reservation) => {
    if (roomPrices[reservation.roomType] - reservation.paid) {
      const message = `Dear ${
        reservation.firstName
      },<br/> <br/> It is time for the second instalment of the retreat accommodation fee. If you haven't already done so, please use the following secure link to pay $${
        roomPrices[reservation.roomType] - reservation.paid
      }.00 <br/> <br/> <a href="https://checkout.square.site/merchant/4K8CR3GSZRSRK/checkout/ZKMAUW4FWJDA5RFJZSMWM4WZ">Payment Link</a> <br\> <br/> Once the payment is completed, kindly take a screenshot of the receipt and send a copy to canaofgalileemarriagefamily@gmail.com. <br/> <br/> Kind Regards, <br/>  Church Retreat Coordinator `;
      const subject = "Second Instalment of Retreat Accommodation Fee";
      const email = reservation.email;
      try {
        const response = await axios.post(
          "https://retreat.canaofgalilee.oo.gd/send-email",
          {
            email,
            subject,
            message,
          }
        );
        alert("Email sent successfully!");
      } catch (error) {
        console.error("Error sending email:", error);
        alert("Failed to send email.");
      }
    }
  };

  const sendReminder = () => {
    const btns = document.getElementsByClassName("reminder");
    for (var i = 0; i < btns.length; i++) {
      btns[i].click();
    }
  };

  useEffect(() => {
    // Replace with your API endpoint to fetch reservations
    // fetch("http://localhost:5000/reservations")
    fetch("https://retreat.canaofgalilee.oo.gd/reservations")
      .then((response) => response.json())
      .then((data) => setReservations(data))
      .catch((error) => console.error("Error fetching reservations:", error));
  }, []);
  useEffect(() => {
    var currentTotal = 0;
    var totalAmount = 0;
    for (var i = 0; i < reservations.length; i++) {
      currentTotal = currentTotal + reservations[i].paid;
      const roomPrice = roomPrices[reservations[i].roomType];
      totalAmount = totalAmount + roomPrice;
    }
    setTotal(currentTotal);
    setOutstanding(totalAmount - currentTotal);
  }, [reservations]);

  function formatToCurrency(amount, locale = "en-AU", currency = "AUD") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(amount);
  }
  return (
    <Container className=" overflow-auto w-full">
      <Row>
        <Col>
          <h2 className=" m-3">Visitors</h2>

          <button className=" m-3 p-2" onClick={sendReminder}>
            Send reminder to all outstanding
          </button>

          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Done</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>email</th>
                  <th>Kids No.</th>
                  <th>Room type</th>
                  <th>Paid</th>
                  <th>outstanding</th>
                  <th>Deposit</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {reservations
                  .filter((reservation) => reservation.roomType === "visit")
                  .map((reservation, index) => (
                    <>
                      <tr key={reservation.id}>
                        <td>
                          {roomPrices[reservation.roomType] -
                          reservation.paid ? (
                            <input type="checkbox" />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{reservation.firstName}</td>
                        <td>{reservation.lastName}</td>
                        <td>{reservation.email}</td>
                        <td>{reservation.numberChild}</td>
                        <td>{reservation.roomType}</td>
                        <td>{reservation.paid}</td>
                        <td>
                          {roomPrices[reservation.roomType] - reservation.paid}
                        </td>
                        <td>
                          <a target="blanck" href={reservation.depositReceipt}>
                            View receipt
                          </a>
                        </td>
                        <td>
                          {roomPrices[reservation.roomType] -
                          reservation.paid ? (
                            <button
                              className="reminder"
                              onClick={() => handelClick(reservation)}
                            >
                              send reminder
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
                {/* <tr>
                  <td colSpan={6} className=" text-center font-bold">
                    <h2>Total</h2>
                  </td>
                  <td>
                    <h2>{formatToCurrency(total)}</h2>
                  </td>
                  <td>
                    <h2>{formatToCurrency(outstanding)}</h2>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className=" m-3">Reservations</h2>

          <button className=" m-3 p-2" onClick={sendReminder}>
            Send reminder to all outstanding
          </button>

          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Done</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>email</th>
                  <th>Kids No.</th>
                  <th>Room type</th>
                  <th>Paid</th>
                  <th>outstanding</th>
                  <th>Deposit</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {reservations
                  .filter((reservation) => reservation.roomType !== "visit")
                  .map((reservation, index) => (
                    <>
                      <tr key={reservation.id}>
                        <td>
                          {roomPrices[reservation.roomType] -
                          reservation.paid ? (
                            <input type="checkbox" />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{reservation.firstName}</td>
                        <td>{reservation.lastName}</td>
                        <td>{reservation.email}</td>
                        <td>{reservation.numberChild}</td>
                        <td>{reservation.roomType}</td>
                        <td>{reservation.paid}</td>
                        <td>
                          {roomPrices[reservation.roomType] - reservation.paid}
                        </td>
                        <td>
                          <a target="blanck" href={reservation.depositReceipt}>
                            View receipt
                          </a>
                        </td>
                        <td>
                          {roomPrices[reservation.roomType] -
                          reservation.paid ? (
                            <button
                              className="reminder"
                              onClick={() => handelClick(reservation)}
                            >
                              send reminder
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
                <tr>
                  <td colSpan={6} className=" text-center font-bold">
                    <h2>Total including visitors</h2>
                  </td>
                  <td>
                    <h2>{formatToCurrency(total)}</h2>
                  </td>
                  <td>
                    <h2>{formatToCurrency(outstanding)}</h2>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
