import { Card, Button } from "react-bootstrap";
import "./RoomCard.css";

const RoomCard = ({ roomId, openModal, roomImg, room, rooms }) => {
  const availableRoom = rooms.filter(
    (roomAvailable) => roomAvailable.room_code === roomId
  );
  return (
    <>
      <Card className="room-card">
        {availableRoom[0].available_rooms === 0 && (
          <div className="not-available">
            <h4
              style={{
                backgroundColor: "red",
                color: "white",
                padding: 5,
                margin: 0,
              }}
            >
              No more available rooms
            </h4>
          </div>
        )}
        <Card.Img variant="top" src={roomImg} alt={roomId} />
        <Card.Body>
          <Card.Title>{room.roomName}</Card.Title>
          <Card.Title>{room.roomPrice}</Card.Title>
          {/* <Card.Title>
            Available : {availableRoom[0].available_rooms}
          </Card.Title> */}

          {roomId !== "visit" && (
            <Button
              onClick={() => {
                openModal(room);
              }}
              aria-controls={roomId}
              aria-expanded={false}
              variant="link"
            >
              Room Details
            </Button>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default RoomCard;
