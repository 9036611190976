import { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import RoomCard from "../components/RoomCard";
import RoomDetailsModal from "../components/RoomDetailsModal";
import axios from "axios";
import mantraPng from "../images/mantra.png";

const Venue = () => {
  const [modalShow, setModalShow] = useState(false);
  const [currentRoom, setCurrentRoom] = useState({});
  const [rooms, setRooms] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMSG] = useState(null);

  useEffect(() => {
    // Fetch available rooms from the backend
    const fetchRooms = async () => {
      try {
        const fetchedRooms = await axios.get(
          "https://retreat.canaofgalilee.oo.gd/rooms"
          // "http://localhost:5000/rooms"
        );

        setRooms(fetchedRooms.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response) {
          // Server responded with a status other than 200 range
          console.error(
            "Server Error:",
            error.response.status,
            error.response.data
          );
        } else if (error.request) {
          // Request was made but no response received
          console.error("Network Error:", error.request);
        } else {
          // Something else happened
          console.error("Error:", error.message);
        }
      }
    };
    fetchRooms();
  }, []);

  const roomsData = [
    {
      roomId: "resort",
      roomName: "resort room",
      roomPrice:
        "$520  for two nights, including breakfast for two adults and kids under 5 years old are free.",
      desc: "These contain everything a single or couple needs for a getaway, including a king bed (zip) that can be divided into two single beds. With tea and coffee making facilities and a bar fridge in your room, enjoy your break with all the entertainment options at your fingertips, such as TV including Chromecast, and broadband internet access. Different pricing available for garden views or filtered ocean views.",
      featuers: [
        "king bed (zip) that can divide into two single beds",
        "tea and coffee making facilities",
        "bar fridge",
        "TV",
        "DVD player",
        "broadband internet access",
        "reverse cycle air-conditioning",
        "resort parking",
        "Resort rooms are serviced every fourth day of stay",
        "Chromecast",
      ],
      img: "/images/resortRoom.jpg",
    },
    {
      roomId: "1bedroom",
      roomName: "1 bedroom garden view apartment",
      roomPrice:
        "$650 for two nights, including breakfast for two adults and kids under 5 years old are free.",
      desc: "These holiday apartments offer the perfect amount of space to relax with facilities ideal for couples, singles or small groups. The bedroom has a queen bed and private ensuite bathroom with some featuring spa baths, plus there is a sofa bed, and a full kitchen. Enjoy your private balcony or terrace where you can step out and admire the view of over 12 acres of landscaped gardens. *Note - As bedding configurations vary please request your requirements at time of booking, but please note this request cannot be guaranteed.",
      featuers: [
        "queen bed",
        "sofa bed",
        "fully equipped kitchen",
        "tea and coffee making facilities",
        "ensuite bathroom",
        "spa equipped baths (some)",
        "balcony with garden view",
        "laundry",
        "separate lounge/dining area",
        "reverse cycle air-conditioning",
        "TV",
        "DVD player",
        "broadband internet access",
        "dedicated parking space at base of building",
        "Apartments are serviced every fourth day of stay",
        "Chromecast",
      ],
      img: "/images/1-Bedroom-Apartment.jpg",
    },
    {
      roomId: "2bedroom",
      roomName: "2 bedroom dual key apartment",
      roomPrice:
        "$1100  for two nights, including breakfast for two adults and kids under 5 years old are free.",
      desc: "Families and groups will love the spaciousness of two bedrooms made possible by adjoining the stylish Resort Room (Studio) and One Bedroom Apartment via a shared foyer, which allows dual key entry. The apartments have a king bed as well as a private ensuite bathroom and a balcony or a patio. With a fully equipped kitchen and separate dining area, you have the option to eat in, along with all the essential entertainment such as Chromecast, DVD player and broadband internet access. Please note - As bedding configurations vary please request your requirements at time of booking, but please note this request cannot be guaranteed. Please note: Due to the scale of the property some room layouts may vary from the pictures.",
      featuers: [
        "king bed (zip) that can divide into two single beds",
        "queen bed",
        "sofa bed",
        "fully equipped kitchen",
        "extra bar fridge",
        "tea and coffee making facilities",
        "ensuite bathroom",
        "spa equipped baths (some)",
        "balcony",
        "laundry",
        "separate lounge/dining area",
        "reverse cycle air-conditioning",
        "TV",
        "DVD player",
        "broadband internet access",
        "dedicated parking at the base of the building",
        "Chromecast",
      ],
      img: "/images/2bedroomdualkeyapartment.jpg",
    },
    {
      roomId: "visit",
      roomName: "Saturday Visitor only",
      roomPrice:
        "$50 per family, Families will attend the holy liturgy and attend Saturday's topics",
      desc: "Families will attend the holy liturgy and attend Saturday's topics",
      featuers: [],
      img: "/images/retreat.jpg",
    },
  ];

  const handleShow = (room) => {
    setCurrentRoom(room);
    setModalShow(true);
  };

  const handleClose = () => setModalShow(false);

  if (loading) {
    return (
      <Container
        style={{ height: "100vh", fontSize: "250px" }}
        className="d-flex justify-content-center align-items-center"
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <>
      {errMsg && (
        <p>
          {" "}
          There is an error, please contact us with the following error message:{" "}
          {errMsg.message}
        </p>
      )}
      <div className="d-flex flex-column justify-content-center mt-5 align-items-center">
        <p className="venue-title">2024 Retreat Venue </p>
        <img src={mantraPng} alt="mantra-logo" />
      </div>

      <Container className="mt-3 venue-container">
        {roomsData.map((room) => (
          <RoomCard
            key={room.roomId}
            roomId={room.roomId}
            openModal={handleShow}
            roomImg={room.img}
            room={room}
            rooms={rooms}
          />
        ))}

        <RoomDetailsModal
          show={modalShow}
          handleClose={handleClose}
          roomDetails={currentRoom}
        />
      </Container>
      {/* <div className="d-flex justify-content-center m-5"> */}
      <h2 className="text-center m-3 p-3">
        Please contact us if you are not sure which room is suitable for you
      </h2>
      {/* </div> */}
    </>
  );
};

export default Venue;
