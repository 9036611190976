import React from "react";
import { Link } from "react-router-dom";
import retreat from "../images/retreat.jpg";

const Home = () => {
  return (
    <div className="container">
      <div
        className="jumbotron"
        style={{
          borderRadius: "15px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <p>
          <i className="fas fa-cross" style={{ fontSize: "2rem" }}></i>
        </p>

        <div className="header text-center mt-2">
          <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>
            In the name of the Father and the Son and The Holy Spirit One God
            Amen
            <br />
            <br /> St. George Coptic Church, St. Albans - 2024 Annual Retreat
            <br />
            From 15/11/2024 to 17/11/2024 at Mantra Lorne
            <br /> In the Spirit of Christ-likeness
          </p>
        </div>
        <img
          src={retreat}
          alt="cover_image"
          style={{
            maxWidth: "90vw",
            width: "400px",
            margin: "0 auto",
            borderRadius: "10px",
          }}
        />
      </div>

      <div className="body">
        <h2> A- RETREAT RESERVATION RULES</h2>
        <ol>
          <li>
            <strong>Room options:</strong>

            <ol>
              <li>Rooms will be available till it last.</li>
              <li>
                The room price is the <strong>total</strong> price for two
                nights, including breakfast for two adults and kids under 5
                years old are free.
              </li>
              <li>
                It is every member's responsibility to check the room
                configuration on <Link to="/venue">"2024 Retreat Venue" </Link>{" "}
                to make sure that it is suitable for his family.{" "}
              </li>
            </ol>
          </li>

          <li>
            <strong>Payment Terms:</strong>
            <ol>
              <li>
                50% Deposit <strong>MUST</strong> be paid at the time of
                reservation via the following{" "}
                <a
                  href="https://checkout.square.site/merchant/4K8CR3GSZRSRK/checkout/ZKMAUW4FWJDA5RFJZSMWM4WZ"
                  target="blank"
                >
                  Payment Link
                </a>
              </li>
              <li>The balance(50%) to be paid by 15/09/2024</li>
            </ol>
          </li>
          <li>
            <strong>Adults Rules:</strong>
            <ol>
              <li>
                Room Accommodations & Meeting Room Retreatants are responsible
                for leaving the hotel facilities in the same condition (or
                better!) in which they were found. This includes the hall and
                its toilets.
              </li>
              <li>
                All materials that can be recycled, including cans, glass and
                plastic bottles, must be cleaned and disposed off.
              </li>

              <li>
                Quiet Hours During the retreat scheduled quiet hours. Please
                respect the experience of others on the property.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div className="body">
        <h2>B- RETREAT ATTENDANCE RULES</h2>
        <ol>
          <li>
            <strong>Kids Rules:</strong>
            <ol>
              <li>
                Parents are required to bring their kids to the activity rooms 5
                minutes before adult's sessions and collect them after sessions.
              </li>
              <li>
                Kids to be guided by parents to cooperate with the servants
                before their stay in the activity rooms.
              </li>
              <li>
                Parents not to interrupt during kids stay in activity room, a
                servant will come to you when required.
              </li>
              <li>
                A register of attendance and leaving time it will be in place
                for parents to sign.
              </li>
            </ol>
          </li>
          <li>
            <strong>General Rules:</strong>
            <ol>
              <li>
                You are expected to attend on time and participate in all
                designed activities as per time table.
              </li>

              <li>All mobile phones must be turned off.</li>
              <li>
                We kindly ask you to avoid any unnecessary movement, unless
                calming infants at the back room.
              </li>
              <li>
                We ask all retreaters, before partaking in the Holy Communion
                to:
                <br />
                -Prepare with a confession;
                <br />
                -Obtain absolution from Abouna.
              </li>
            </ol>
          </li>
        </ol>
      </div>

      <div className="body">
        <h3>God Bless You All</h3>
        <Link to="/reserve">
          <button className="btn btn-success" style={{ marginBottom: "100px" }}>
            I Agree
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Home;
