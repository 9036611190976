import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./pages/Home";
import ReservationForm from "./components/ReservationForm";
import Venue from "./pages/Venue";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./App.css"; // Optionally add global styles
import NotFound from "./components/NotFound";
import AdminDashboard from "./components/AdminDashboard";

const App = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);
  const handleClose = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };

  return (
    <Router>
      <div className="app">
        <Navbar
          sticky="top"
          bg="dark"
          variant="dark"
          expand="lg"
          expanded={expanded}
        >
          <Container>
            <Navbar.Brand href="/">Cana Of Galilee</Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={handleToggle}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/" onClick={handleClose}>
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/venue" onClick={handleClose}>
                  Venue
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reserve" element={<ReservationForm />} />
          <Route path="/venue" element={<Venue />} />
          <Route path="/dashboard" element={<AdminDashboard />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
